<template>
  <section id="apercu">
    <invoice-list v-if="userData != null" :user-data="userData" />
  </section>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import axios from '@axios'
import InvoiceList from './InvoiceList.vue'
import {
  BCard, BRow, BCol, BButton, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormFile, BCardText, BSpinner, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from './userStoreModule'

export default {
  components: {
    InvoiceList,
    BCard,
    BRow,
    BCol,
    BButton,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormFile,
    BCardText,
    BSpinner,
    BFormTextarea,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
  data() {
    return {
      messages: [],
      ready: false,
      encours: null,
      edit: null,
      file: null,
      text: null,
    }
  },
  methods: {
    formSubmitted() {
      this.encours = 'hey'
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('message', this.text)
      this.message = null
      this.file = null

      axios
        .post('https://app2.oscar-black.com/api/message', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.encours = null
          this.messages = response.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le fichier a bien été envoyé !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.encours = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erreur',
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    editEnCours(val) {
      this.edit = val
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        text: 'Il sera impossible de revenir en arrière...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer ce design',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/design', { data: req })
            .then(response => {
              this.designs = response.data
              this.$swal({
                icon: 'success',
                title: 'Supprimé !',
                text: 'Votre design a bien été supprimé.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Oops, pas supprimé !',
                text: error.response.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Annulé',
            text: 'Votre design est en sécurité :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/message')
      .then(response => {
        this.messages = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
